<template>
  <div class=container>
      <table  width="100%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of Separation Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of our separation results. In each case, we mention the separation method we have used. In all cases, the scene image is the sum of the computed direct and global images. However, to bring out the details, for some of the scenes we have brightened the direct and global images by a scale factor (between 1 and 2) with respect to the scene image. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br></td>
          </tr>
          
                  <router-link :to="{path:'/projects/categories/project', query:{cid:'Computational Imaging',pid:'Fast Separation of Direct and Global Images'}}">(Back to Main Project Page) </router-link>
<tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td><strong>Photometric Stereo : Painted Bowl</strong></td>
                        <td><div align="right"><strong>Method : Line Occluder</strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
        <tbody><tr>
                      <td width="32%"><div align="center"><strong>Source 1</strong></div></td>
                      <td width="35%"><div align="center"><strong>Source 2</strong></div></td>
                      <td width="33%"><div align="center"><strong>Source 3</strong></div></td>
                      </tr>	

                <tr>
                        <td><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Regular1.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Regular1.jpg" width="175" height="175" border="0"></a></div></td>
                        <td><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Regular2.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Regular2.jpg" width="175" height="175" border="0"></a></div></td>
                        <td><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Regular3.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Regular3.jpg" width="175" height="175" border="0"></a></div></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">Regular Images</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Global1.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Global1.jpg" width="175" height="175" border="0"></a></div></td>
                        <td><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Global2.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Global2.jpg" width="175" height="175" border="0"></a></div></td>
                        <td><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Global3.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Global3.jpg" width="175" height="175" border="0"></a></div></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">Global Images</div></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Direct1.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Direct1.jpg" width="175" height="175" border="0"></a></div></td>
                        <td><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Direct2.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Direct2.jpg" width="175" height="175" border="0"></a></div></td>
                        <td><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/Direct3.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_Direct3.jpg" width="175" height="175" border="0"></a></div></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td><div align="center">Direct Images</div></td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody></table>
 

 <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>The top row shows three images of a diffuse painted bowl captured under three different light source directions. The second and third rows show the corresponding global and direct images obtained using a stick occluder.</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellpadding="10" cellspacing="0" bordercolor="#000000" bgcolor="#F6F4EC">
                      <tbody><tr>
                        <td width="35%"><a href="https://cave.cs.columbia.edu/old/projects/separation/images/PS.jpg"><img src="https://cave.cs.columbia.edu/old/projects/separation/thumbnails/Thumb_PS.jpg" width="200" height="162"></a></td>
                        <td width="65%" valign="top"><strong>Photometric Stereo Results :</strong> Here we show the actual shape of the bowl measured manually(yellow) and  the shapes computed by applying photometric stereo to the regular images(blue) and the direct images(red). As expected, the regular images produce a much shallower shape due to interreflections, while the direct images produce the correct shape.</td>
                      </tr>
                  </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          
          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
</style>